.autoInfo {
  padding: 0.3rem 0.16rem;
}
.autoInfo .auto_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
}
.autoInfo .auto_item .yy_img {
  width: 3rem;
}
