
.autoInfo {
  padding: 0.3rem 0.16rem;
  .auto_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    .yy_img {
      width: 3rem;
    }
  }
}
